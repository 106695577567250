import { memo, useContext, useMemo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// components
import { NavSectionHorizontal } from 'src/components/nav-section';
import { AuthContext } from 'src/auth/context/superadmin';

//
import { HEADER } from '../config-layout';
import { useNavData } from './config-navigation';
import { HeaderShadow } from '../_common';

// ----------------------------------------------------------------------

function NavHorizontal() {
  const theme = useTheme();

  const { user } = useMockedUser();

  const navData = useNavData();

  const { rolesAndPermissions } = useContext(AuthContext) as any;

  const allowedTitles = useMemo(() => 
    rolesAndPermissions === null 
      ? [] 
      : rolesAndPermissions?.map((role: any) => role?.name?.trim().toLowerCase()), 
    [rolesAndPermissions]
  );

  const filteredNavData = useMemo(() => {
    if (!rolesAndPermissions) {
      return [];
    }

    return navData.map(section => ({
      ...section,
      items: section.items.filter(item => {
        if (item.title.trim().toLowerCase() === 'permission') {
          return allowedTitles.length > 0;
        }
        return allowedTitles.includes(item.title.trim().toLowerCase());
      })
    }));
  }, [navData, allowedTitles, rolesAndPermissions]);


  return (
    <AppBar
      component="nav"
      sx={{
        top: HEADER.H_DESKTOP_OFFSET,
      }}
    >
      <Toolbar
        sx={{
          ...bgBlur({
            color: theme.palette.background.default,
          }),
        }}
      >
        <NavSectionHorizontal
          data={filteredNavData}
          config={{
            currentRole: user?.role || 'admin',
          }}
        />
      </Toolbar>

      <HeaderShadow />
    </AppBar>
  );
}

export default memo(NavHorizontal);
