import axios, { AxiosRequestConfig } from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};
export const postRequest = async (url: string, data: any, config?: AxiosRequestConfig) => {
  try {
    const response = await axiosInstance.post(url, data, config);
    return response.data;
  } catch (error) {
    console.error('Error making POST request:', error);
    throw error;
  }
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/super-admin/admin/login',
    getProfile: '/super-admin/admin/get-profile',
    // client login
    clientlogin: '/client/auth/login',
    getClientProfile: '/client/auth/get-profile',
    // contractor login
    contractorlogin: '/contractor/auth/login',
    getConstrationProfile: '/contractor/auth/get-profile',
    // karntekadmin login
    karntekadminlogin: '/admin/auth/login',
    getKarntekProfile: '/admin/auth/get-profile',
    // register 
    register: '/api/auth/register',
  },
  // super admin endpoints start --------------------------------------------

  superadmindashboard:{
    dashboard: '/admin/dashboard/get-counts',
    changePassword:'/admin/auth/change-password',
    updateProfile:'/admin/auth/update-profile',
    updateProfileURL:'/admin/auth/upload-user-img',
  },
  client: {
    addclient: '/super-admin/company/add-edit-company',
    getcompony: '/super-admin/company/get-company',
    country: '/super-admin/master/get-country',
    state: '/super-admin/master/get-state',
    city: '/super-admin/master/get-city',
    deleteclient: '/super-admin/company/delete-company',
    getcompanytype: '/super-admin/company/get-company-type',
  },
  superadminuser: {
    getUser: '/super-admin/user/get-user',
    addUser: '/super-admin/user/add-edit-user',
    deleteUser: '/super-admin/user/delete-user',
    updateUserstatus: '/super-admin/user/update-satatus-user',
  },
  common: {
    getrolelist: '/super-admin/master/get-role'
  },

  // super admin endpoints end --------------------------------------------

  // karntek admin endpoints start --------------------------------------------

  karntekadmindashboard:{
    dashboard: '/admin/dashboard/get-counts',
    changePassword:'/admin/auth/change-password',
    updateProfile:'/admin/auth/update-profile',
    updateProfileURL:'/admin/auth/upload-user-img',

  },
  karntekadminclient: {
    addclient: '/admin/company/add-edit-company',
    getcompony: '/admin/company/get-company',
    country: '/admin/master/get-country',
    state: '/admin/master/get-state',
    city: '/admin/master/get-city',
    deleteclient: '/admin/company/delete-company',
    getcompanytype: '/admin/company/get-company-type',
    docupload: '/admin/company/upload-company-doc',
    getcompanydoc: '/admin/company/get-company-doc',
    deletedoc: '/admin/company/delete-company-doc',
    getclientstatus: '/admin/company/update-satatus-company',
    uploadClientprofile: '/admin/company/upload-company-logo',
    getsystembuildingId: 'admin/building/get-system-building-id',
    getAllAdminUser: 'admin/user/get-all-user',
    getAllCompanyUser: '/admin/company/get-company-user',
    unassignCompany: '/admin/company/unassign-company',
    importclient:"/admin/company/import-company"

  },

  karntekadminbuilding: {
    addbuilding: '/admin/building/add-edit-building',
    getbuilding: '/admin/building/get-building',
    getallbuilding: '/admin/building/get-all-building',
    getallbuildingmap: '/admin/building/get-all-building',
    category: '/admin/building/get-building-category',
    subcategory: '/admin/building/get-building-sub-category',
    deletebuilding: '/admin/building/delete-building',
    buildingupdatestatus: '/admin/building/update-satatus-building',
    uploadBuildingprofile: '/admin/building/upload-building-img',
    getBuildingDocs: '/admin/building/get-building-doc',
    uploadBuildingDoc: '/admin/building/upload-building-doc',
    assignCompany: '/admin/building/assign-building',
    unassignBuilding: '/admin/building/unassign-building',
    getAllBuildingUser: '/admin/building/get-building-users',
    buildingArchiveDocument: '/admin/building/archive-building-doc',
    buildingUnArchiveDocument: '/admin/building/unarchive-building-doc',
    buildingDeleteDocument: '/admin/building/delete-building-doc',
    buildingAddDocDate: '/admin/building/add-doc-date',
    getArchiveDocuments: '/admin/building/get-archive-building-doc',
    importbuilding:"/admin/building/import-building"
  },
  karntekadminuser: {
    getUser: '/admin/user/get-user',
    addUser: '/admin/user/add-edit-user',
    deleteUser: '/admin/user/delete-user',
    updateUserstatus: '/admin/user/update-satatus-user',
    uploadProfileimage: '/admin/user/upload-user-img',
    assignCompany: '/admin/company/assign-company',
  },

  karntekadminpermission: {
    getGroup: '/admin/permission/get-groups',
    getPermission:'/admin/permission/get-permission',
    getGroupPermission:'/admin/permission/get-group-permission',
    getGroupUsers:'/admin/permission/get-group-users',
    addPermission: '/admin/permission/add-edit-group', 
    deleteGroup:'/admin/permission/delete-group'
  },

  clientadminpermission: {
    getGroup: '/client/permission/get-groups',
    getPermission:'/client/permission/get-permission',
    getGroupPermission:'/client/permission/get-group-permission',
    getGroupUsers:'/client/permission/get-group-users',
    addPermission: '/client/permission/add-edit-group', 
    deleteGroup:'/client/permission/delete-group'
  },
  contractoradminpermission: {
    getGroup: '/contractor/permission/get-groups',
    getPermission:'/contractor/permission/get-permission',
    getGroupPermission:'/contractor/permission/get-group-permission',
    getGroupUsers:'/contractor/permission/get-group-users',
    addPermission: '/contractor/permission/add-edit-group', 
    deleteGroup:'/contractor/permission/delete-group'
  },
  karntekadminassets: {
    getAssets: '/admin/asset/get-asset',
    addAssets: '/admin/asset/add-edit-asset',
    deleteUAssets: '/admin/asset/delete-asset',
    getAssetsType: '/admin/asset/get-asset-type',
    getBuildingAssets: 'admin/asset/get-building-asset',
    updateAssetstatus: '/admin/asset/update-satatus-asset',
    importAssets:"/admin/asset/import-asset"

  },
  karntekadminContact: {
    getcontant: '/admin/contact/get-contact',
    addcontact: '/admin/contact/add-edit-contact',
    deleteContact: '/admin/contact/delete-contact',
    task: '/admin/task/get-task'
  },
  karntekcommon: {
    karntekgetrolelist: '/admin/master/get-role',
  },
  karntekadminTask: {
    getAllTask: '/admin/task/get-task',
    deleteTask: '/admin/task/delete-task',
    addTask: '/admin/task/add-edit-task',
    uploadTaskDoc:'/admin/task/task-status-update',
    getTaskHistory: '/admin/task/get-task-history',
    importTask:"/admin/task/import-task"

  },
  karntekadminMaster: {
    getMasterStatus: '/admin/master/get-master-status',
  },

  // karntek admin endpoints end --------------------------------------------
  clientdashboard:{
    dashboard: '/client/dashboard/get-counts',
    changePassword:'/client/auth/change-password',
    updateProfile:'/client/auth/update-profile',
    updateProfileURL:'/client/auth/upload-user-img',
  },

  clientadminuser: {
    getUser: '/client/user/get-user',
    addUser: '/client/user/add-edit-user',
    deleteUser: '/client/user/delete-user',
    updateUserstatus: '/client/user/update-satatus-user',
    uploadProfileimage: '/client/user/upload-user-img',
    getRole: '/client/master/get-role',
    getAllAdminUser: '/client/user/get-all-user'
  },

  clientadminclient: {
    addclient: '/admin/company/add-edit-company',
    getcompony: '/client/company/get-company',
    country: '/client/master/get-country',
    state: '/client/master/get-state',
    city: '/client/master/get-city',
    deleteclient: '/admin/company/delete-company',
    getcompanytype: '/client/company/get-company-type',
    docupload: '/client/company/upload-company-doc',
    getcompanydoc: '/client/company/get-company-doc',
    deletedoc: '/client/company/delete-company-doc',
    getclientstatus: '/admin/company/update-satatus-company',
    uploadClientprofile: '/client/company/upload-company-logo',
    getsystembuildingId: 'client/building/get-system-building-id',
    getAllCompanyUser: '/client/company/get-company-user',
    assignCompany: '/client/company/assign-company',
    unassignCompany: '/client/company/unassign-company'
  },

  clientadminbuilding: {
    addbuilding: '/client/building/add-edit-building',
    getbuilding: '/client/building/get-building',
    getallbuilding: '/client/building/get-all-building',
    category: '/client/building/get-building-category',
    subcategory: '/client/building/get-building-sub-category',
    uploadBuildingprofile: '/client/building/upload-building-img',
    getBuildingDocs: '/client/building/get-building-doc',
    uploadBuildingDoc: '/client/building/upload-building-doc',
    getuserBuilding: '/client/building/get-user-building',
  },

  clientadminassets: {
    getAssets: '/client/asset/get-asset',
    addAssets: '/client/asset/add-edit-asset',
    deleteUAssets: '/client/asset/delete-asset',
    getAssetsType: '/client/asset/get-asset-type',
    getBuildingAssets: '/client/asset/get-building-asset',
    updateAssetstatus: '/client/asset/update-satatus-asset',
  },

  clientadminacontact: {
    getcontant: '/client/contact/get-contact',
    addcontact: '/client/contact/add-edit-contact',
    deleteContact: '/client/contact/delete-contact',
    task: '/client/task/get-task'
  },
  clientAdminTask: {
    getAllTask: '/client/task/get-task',
    deleteTask: '/client/task/delete-task',
    addTask: '/client/task/add-edit-task',
    updatestatus: '/client/task/update-satatus-task',
  },
  clientadminMaster: {
    getMasterStatus: '/client/master/get-master-status',
  },
  ClientAdminContact: {
    getcontant: '/admin/contact/get-contact',
    addcontact: '/admin/contact/add-edit-contact',
    deleteContact: '/admin/contact/delete-contact',
    task: '/admin/task/get-task'
  },

  // contractor endpoints -----

  contractordashboard:{
    dashboard: '/contractor/dashboard/get-counts',
    changePassword:'/contractor/auth/change-password',
    updateProfile:'/contractor/auth/update-profile',
    updateProfileURL:'/contractor/auth/upload-user-img',
  },

  contractoradminuser: {
    getUser: '/contractor/user/get-user',
    addUser: '/contractor/user/add-edit-user',
    deleteUser: '/contractor/user/delete-user',
    updateUserstatus: '/contractor/user/update-satatus-user',
    uploadProfileimage: '/contractor/user/upload-user-img',
    getRole: '/contractor/master/get-role',
    getAllAdminUser: '/contractor/user/get-all-user'
  },

  contractoradminclient: {
    getcompony: '/contractor/company/get-company',
    country: '/contractor/master/get-country',
    state: '/contractor/master/get-state',
    city: '/contractor/master/get-city',
    getcompanytype: '/contractor/company/get-company-type',
    getcompanydoc: '/contractor/company/get-company-doc',
    assignCompany: '/contractor/company/assign-company',
    getAllContractorCompanyUser: '/contractor/company/get-company-user',
    unassignCompany: '/contractor/company/unassign-company'
  },
  contractorAdminTask: {
    getAllTask: '/contractor/task/get-task',
  },

  contractoradminbuilding: {
    category: '/contractor/building/get-building-category',
    subcategory: '/contractor/building/get-building-sub-category',
    getbuilding: '/contractor/building/get-building',
    getBuildingDocs: '/contractor/building/get-building-doc',
    assignCompany: '/contractor/building/assign-building',
    unassignBuilding: '/contractor/building/unassign-building',
    getAllBuildingUser: '/contractor/building/get-building-users'
  },
  contractoradminassets: {
    getAssetsType: '/contractor/asset/get-asset-type',
    getAssets: '/contractor/asset/get-asset',
    getBuildingAssets: '/contractor/asset/get-building-asset',
 
  },
  contractoradmincontact: {
    getcontant: '/contractor/contact/get-contact',
    
  },

  cntractorAdminTask: {
    getAllTask: '/contractor/task/get-task',
  },



  // Theme endpoints

  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};
