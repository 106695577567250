import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard, AuthKarntekGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import SuperAdminDashboardLayout from 'src/layouts/superadmin/layout';
import ClientDashboardLayout from 'src/layouts/client/layout';
import ContractorDashboardLayout from 'src/layouts/contractor/layout';
import KarntekAdminDashboardLayout from 'src/layouts/karntek/layout';

// --------------------------------------------------------------------

// OVERVIEW

const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));

const UserAccountPage = lazy(() => import('src/pages/karntekdashboard/account'));

// const KarntekOverviewUserPage = lazy(() => import('src/pages/karntekdashboard/user'));

// SUPER ADMIN ROUTES
const SuperadminIndexPage = lazy(() => import('src/pages/superadmindashboard/superadmin'));
const OverviewUserPage = lazy(() => import('src/pages/superadmindashboard/user'));
const OverviewClientPage = lazy(() => import('src/pages/superadmindashboard/client'));
const OverviewContactPage = lazy(() => import('src/pages/superadmindashboard/contact'));
const OverviewTaskPage = lazy(() => import('src/pages/superadmindashboard/task'));
const OverviewReportPage = lazy(() => import('src/pages/superadmindashboard/report'));
const OverviewAssetsPage = lazy(() => import('src/pages/superadmindashboard/assets'));
// super admin Client
const AddClientPage = lazy(() => import('src/pages/superadmindashboard/addclient'));
const EditClientPage = lazy(() => import('src/pages/superadmindashboard/editClient'));
// super admin user
const AddUserPage = lazy(() => import('src/pages/superadmindashboard/addUser'));
const EditUserPage = lazy(() => import('src/pages/superadmindashboard/editUser'));



// CLIENT ADMIN ROUTES
const ClientadminIndexPage = lazy(() => import('src/pages/clientdashboard/clientdashboard'));
const ClientOverviewUserPage = lazy(() => import('src/pages/clientdashboard/user'));
const ClientOverviewReportPage = lazy(() => import('src/pages/clientdashboard/report'));

const ClientOverviewAssetsPage = lazy(() => import('src/pages/clientdashboard/assets'));
const AddClientAdminAssetsPage = lazy(() => import('src/pages/clientdashboard/addAssets'));
const EditClientAdminAssetsPage = lazy(() => import('src/pages/clientdashboard/editAssets'));
const ClientAdminAssetsView = lazy(() => import('src/pages/clientdashboard/assetsView'));

const ClientOverviewClientPage = lazy(() => import('src/pages/clientdashboard/client'));
const AddclientadminClientPage = lazy(() => import('src/pages/clientdashboard/addclient'));
const EditClientadminkUserPage = lazy(() => import('src/pages/clientdashboard/editclient'));
const ClientDocDetailsViewPage = lazy(() => import('src/pages/clientdashboard/clientdocdetailsview'));


const ClientOverviewTaskPage = lazy(() => import('src/pages/clientdashboard/task'));
const AddClientAdminTaskPage = lazy(() => import('src/pages/clientdashboard/addTask'));
const EditClientAdminTaskPage = lazy(() => import('src/pages/clientdashboard/editTask'));
const ClientadminTaskView = lazy(() => import('src/pages/clientdashboard/taskView'));

const ClientadminUserDetailView = lazy(() => import('src/pages/clientdashboard/UserView'));
const AddClientadminUserPage = lazy(() => import('src/pages/clientdashboard/adduser'));
const EditClientadminUserPage = lazy(() => import('src/pages/clientdashboard/edituser'));
const ClientadminClientDetailView = lazy(() => import('src/pages/clientdashboard/clientdetailview'));

const ClientOverviewContactPage = lazy(() => import('src/pages/clientdashboard/contact'));
const AddClientAdminContactsPage = lazy(() => import('src/pages/clientdashboard/addContacts'));
const ClientAdminContactDetailsView = lazy(() => import('src/pages/clientdashboard/ClientAdminContactdetailsview'));
const EditClientAdminContactsPage = lazy(() => import('src/pages/clientdashboard/editContacts'));

const ClientAdminBuildingDetailView = lazy(() => import('src/pages/clientdashboard/building'));
const ClientAdminBuildingView = lazy(() => import('src/pages/clientdashboard/BuildingView'));
const AddClientAdminBuildingPage = lazy(() => import('src/pages/clientdashboard/addbuilding'));
const EditClientAdminBuildingPage = lazy(() => import('src/pages/clientdashboard/editBuilding'));
const ClientAdminBuildingDocView = lazy(() => import('src/pages/clientdashboard/BuildingDocView'));



// Contractor ROUTES
const ContractorIndexPage = lazy(() => import('src/pages/contractorDashboard/contractorDashboard'));
// const ClientOverviewReportPage = lazy(() => import('src/pages/clientdashboard/report'));

const ContractorOverviewAssetsPage = lazy(() => import('src/pages/contractorDashboard/assets'));
const AddContractorAssetsPage = lazy(() => import('src/pages/contractorDashboard/addAssets'));
const EditContractorAssetsPage = lazy(() => import('src/pages/contractorDashboard/editAssets'));
const ContractorAssetsView = lazy(() => import('src/pages/contractorDashboard/assetsView'));

const ContractorOverviewClientPage = lazy(() => import('src/pages/contractorDashboard/client'));
const AddContractorClientPage = lazy(() => import('src/pages/contractorDashboard/addclient'));
const EditContractorClientPage = lazy(() => import('src/pages/contractorDashboard/editclient'));
const ContractorClientDetailView = lazy(() => import('src/pages/contractorDashboard/clientDetailView'));
const ContractorClientDocDetailsViewPage = lazy(() => import('src/pages/contractorDashboard/clientDocDetailsView'));

const ContractorOverviewTaskPage = lazy(() => import('src/pages/contractorDashboard/task'));
const AddContractorTaskPage = lazy(() => import('src/pages/contractorDashboard/addTask'));
const EditContractorTaskPage = lazy(() => import('src/pages/contractorDashboard/editTask'));
const ContractorTaskView = lazy(() => import('src/pages/contractorDashboard/taskView'));

const ContractorOverviewUserPage = lazy(() => import('src/pages/contractorDashboard/user'));
const ContractorUserView = lazy(() => import('src/pages/contractorDashboard/UserView'));
const AddContractorUserPage = lazy(() => import('src/pages/contractorDashboard/adduser'));
const EditContractorUserPage = lazy(() => import('src/pages/contractorDashboard/edituser'));

const ContractorOverviewContactPage = lazy(() => import('src/pages/contractorDashboard/contact'));
const AddContractorContactsPage = lazy(() => import('src/pages/contractorDashboard/addContacts'));
const ContractorContactDetailsView = lazy(() => import('src/pages/contractorDashboard/ContractorContactdetailsview'));
const EditContractorContactsPage = lazy(() => import('src/pages/contractorDashboard/editContacts'));

const ContractorOverviewBuildingPage = lazy(() => import('src/pages/contractorDashboard/building'));
const ContractorBuildingView = lazy(() => import('src/pages/contractorDashboard/BuildingView'));
const AddContractorBuildingPage = lazy(() => import('src/pages/contractorDashboard/addbuilding'));
const EditContractorBuildingPage = lazy(() => import('src/pages/contractorDashboard/editBuilding'));
const ContractorBuildingDocView = lazy(() => import('src/pages/contractorDashboard/BuildingDocView'));


// KARTAK ADMIN ROUTES
const KartakinIndexPage = lazy(() => import('src/pages/karntekdashboard/karntekdashboard'));
const KarntekOverviewUserPage = lazy(() => import('src/pages/karntekdashboard/user'));
const UserView = lazy(() => import('src/pages/karntekdashboard/UserView'));

const AddKarntekUserPage = lazy(() => import('src/pages/karntekdashboard/adduser'));
const EditKarntekUserPage = lazy(() => import('src/pages/karntekdashboard/edituser'));
const KarntekOverviewClientPage = lazy(() => import('src/pages/karntekdashboard/client'));
const AddKarntekClientPage = lazy(() => import('src/pages/karntekdashboard/addclient'));
const EditKarntekClientPage = lazy(() => import('src/pages/karntekdashboard/editclient'));
const ClientDetailView = lazy(() => import('src/pages/karntekdashboard/clientdetailview'));
const ClientDocDetailsView = lazy(() => import('src/pages/karntekdashboard/clientdocdetailsview'));
const ContactDetailsView = lazy(() => import('src/pages/karntekdashboard/contactdetailsview'));
const KarntekOverviewBuildingPage = lazy(() => import('src/pages/karntekdashboard/building'));
const BuildingView = lazy(() => import('src/pages/karntekdashboard/BuildingView'));
const AddKarntekBuildingPage = lazy(() => import('src/pages/karntekdashboard/addbuilding'));
const EditKarntekBuildingPage = lazy(() => import('src/pages/karntekdashboard/editBuilding'));
const BuildingDocView = lazy(() => import('src/pages/karntekdashboard/BuildingDocView'));
const AddKarntekAssetsPage = lazy(() => import('src/pages/karntekdashboard/addAssets'));
const EditKarntekAssetsPage = lazy(() => import('src/pages/karntekdashboard/editAssets'));

const KarntekOverviewContactPage = lazy(() => import('src/pages/karntekdashboard/contact'));
const AddKarntekContactsPage = lazy(() => import('src/pages/karntekdashboard/addContacts'));
const EditKarntekContactsPage = lazy(() => import('src/pages/karntekdashboard/editContacts'));
const KarntekOverviewTaskPage = lazy(() => import('src/pages/karntekdashboard/task'));
const AddKarntekTaskPage = lazy(() => import('src/pages/karntekdashboard/addTask'));
const TaskView = lazy(() => import('src/pages/karntekdashboard/taskView'));
const EditKarntekTaskPage = lazy(() => import('src/pages/karntekdashboard/editTask'));
const KarntekOverviewReportPage = lazy(() => import('src/pages/karntekdashboard/report'));
const KarntekOverviewAssetsPage = lazy(() => import('src/pages/karntekdashboard/assets'));

const AssetsView = lazy(() => import('src/pages/karntekdashboard/assetsView'));

const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));

// permission
const KarntekOverviewPermissionPage = lazy(() => import('src/pages/karntekdashboard/permssion'));

const AddPermissionPage = lazy(() => import('src/pages/karntekdashboard/addpermission'));
const EditPermissionPage = lazy(() => import('src/pages/karntekdashboard/editpermission'));

const ClientOverviewPermissionPage = lazy(() => import('src/pages/clientdashboard/permssion'));

const AddclientPermissionPage = lazy(() => import('src/pages/clientdashboard/addpermission'));
const EditclientPermissionPage = lazy(() => import('src/pages/clientdashboard/editpermission'));

const ContractorOverviewPermissionPage = lazy(() => import('src/pages/contractorDashboard/permssion'));

const AddContractorPermissionPage = lazy(() => import('src/pages/contractorDashboard/addpermission'));
const EditContractorPermissionPage = lazy(() => import('src/pages/contractorDashboard/editpermission'));


// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'ecommerce', element: <OverviewEcommercePage /> },
      { path: 'analytics', element: <OverviewAnalyticsPage /> },
      { path: 'banking', element: <OverviewBankingPage /> },
      { path: 'booking', element: <OverviewBookingPage /> },
      { path: 'file', element: <OverviewFilePage /> },
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
        ],
      },

   
      { path: 'file-manager', element: <FileManagerPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
    ],
  },
];

export const superadminroutes = [
  {
    path: 'superadmin',
    element: (
      <AuthGuard>
        <SuperAdminDashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </SuperAdminDashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <SuperadminIndexPage />, index: true },
      { path: 'client', element: <OverviewClientPage /> },
      { path: 'contact', element: <OverviewContactPage /> },
      { path: 'task', element: <OverviewTaskPage /> },
      { path: 'report', element: <OverviewReportPage /> },
      { path: 'assets', element: <OverviewAssetsPage /> },
      { path: 'addclient', element: <AddClientPage /> },
      { path: ':id/editClient', element: <EditClientPage /> },
      {
        path: 'user',
        children: [
          { element: <OverviewUserPage />, index: true },
          { path: 'addUser', element: <AddUserPage /> },
          { path: ':id/editUser', element: <EditUserPage /> },


        ],
      },

    ],
  },
];
// 

export const clientroutes = [
  {
    path: 'client',
    element: (
      <AuthGuard>
        <ClientDashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </ClientDashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <ClientadminIndexPage />, index: true },

      { path: 'report', element: <ClientOverviewReportPage /> },

      {
        path: 'user',
        children: [
          { element: <ClientOverviewUserPage />, index: true },
          { path: 'addUser', element: <AddClientadminUserPage /> },
          { path: ':id/editUser', element: <EditClientadminUserPage /> },
          { path: ':id/userView', element: <ClientadminUserDetailView /> },
        ],
      },
      {
        path: 'client',
        children: [
          { element: <ClientOverviewClientPage />, index: true },
          { path: 'addclient', element: <AddclientadminClientPage /> },
          { path: ':id/editClient', element: <EditClientadminkUserPage /> },
          { path: ':id/clientdetailview/:selectedId', element: <ClientadminClientDetailView /> },
          { path: ':id/clientdocdetailsview/:company_type_id', element: <ClientDocDetailsViewPage /> },
        ],
      },
      {
        path: 'assets',
        children: [
          { element: <ClientOverviewAssetsPage />, index: true },
          { path: 'addAssets', element: <AddClientAdminAssetsPage /> },
          { path: ':id/editAssets/:building_id', element: <EditClientAdminAssetsPage /> },
          { path: ':id/assetsView/:selectedbuildingId', element: <ClientAdminAssetsView /> },
        ],
      },
      {
        path: 'task',
        children: [
          { element: <ClientOverviewTaskPage />, index: true },
          { path: 'addTask', element: <AddClientAdminTaskPage /> },
          { path: ':id/editTask/:building_id', element: <EditClientAdminTaskPage /> },
          { path: ':id/taskView/:selectedbuildingId', element: <ClientadminTaskView /> },
        ],
      },
      {
        path: 'contact',
        children: [
          { element: <ClientOverviewContactPage />, index: true },
          { path: 'addContacts', element: <AddClientAdminContactsPage /> },
          { path: ':id/editContacts/:company_id', element: <EditClientAdminContactsPage /> },
          { path: ':id/ContactDetailsView', element: <ClientAdminContactDetailsView /> },
        ],
      },
      {
        path: 'building',
        children: [
          { element: <ClientAdminBuildingDetailView />, index: true },
          { path: 'addbuilding', element: <AddClientAdminBuildingPage /> },
          { path: ':id/buildingView', element: <ClientAdminBuildingView /> },
          { path: ':id/editbuilding', element: <EditClientAdminBuildingPage /> },
          { path: ':id/buildingDocView', element: <ClientAdminBuildingDocView /> },
        ],
      },

      {
        path: 'permission',
        children: [
          { element: <ClientOverviewPermissionPage />, index: true },
          { path: 'addpermission', element: <AddclientPermissionPage /> },
          { path: ':id/editpermission', element: <EditclientPermissionPage /> },

        ],
      },



    ],
  },
]

export const karntekroutes = [
  {
    path: 'karntek',
    element: (
      <AuthGuard>
        <KarntekAdminDashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </KarntekAdminDashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <KartakinIndexPage />, index: true },
      { path: 'client', element: <KarntekOverviewClientPage /> },
      { path: 'contact_1', element: <KarntekOverviewContactPage /> },
      { path: 'task', element: <KarntekOverviewTaskPage /> },
      { path: 'report', element: <KarntekOverviewReportPage /> },
      { path: 'assets', element: <KarntekOverviewAssetsPage /> },
      { path: ':id/editClient/:company_type_id', element: <EditKarntekClientPage /> },
      { path: ':id/clientdetailview/:selectedId', element: <ClientDetailView /> },
      { path: ':id/clientdocdetailsview/:company_type_id', element: <ClientDocDetailsView /> },
      { path: ':id/taskView/:selectedbuildingId', element: <TaskView /> },
      { path: ':id/editTask/:building_id', element: <EditKarntekTaskPage /> },

      { path: 'contact', element: <KarntekOverviewContactPage /> },
      { path: 'addContacts', element: <AddKarntekContactsPage /> },
      { path: ':id/ContactDetailsView', element: <ContactDetailsView /> },
      { path: ':id/editContacts/:company_id', element: <EditKarntekContactsPage /> },

      { path: 'building', element: <KarntekOverviewBuildingPage /> },
      { path: 'addbuilding', element: <AddKarntekBuildingPage /> },
      { path: ':id/buildingView', element: <BuildingView /> },
      { path: ':id/editbuilding', element: <EditKarntekBuildingPage /> },
      { path: ':id/buildingDocView', element: <BuildingDocView /> },

      { path: ':id/userView', element: <UserView /> },
      { path: ':id/assetsView/:selectedbuildingId', element: <AssetsView /> },


      { path: 'addclient', element: <AddKarntekClientPage /> },
      { path: 'addUser', element: <AddUserPage /> },
      { path: ':id/editUser', element: <EditUserPage /> },
      { path: 'addAssets', element: <AddKarntekAssetsPage /> },
      { path: ':id/editAssets/:building_id', element: <EditKarntekAssetsPage /> },
      { path: 'addTask', element: <AddKarntekTaskPage /> },


      {
        path: 'user',
        children: [
          { element: <KarntekOverviewUserPage />, index: true },
          { path: 'addUser', element: <AddKarntekUserPage /> },
          { path: ':id/editUser', element: <EditKarntekUserPage /> },
          { path: 'account', element: <UserAccountPage /> },

        ],
      },

      {
        path: 'permission',
        children: [
          { element: <KarntekOverviewPermissionPage />, index: true },
          { path: 'addpermission', element: <AddPermissionPage /> },
          { path: ':id/editpermission', element: <EditPermissionPage /> },

        ],
      },



    ],
  },
];


export const contractorRoutes = [
  {
    path: 'contractor',
    element: (
      <AuthGuard>
        <ContractorDashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </ContractorDashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <ContractorIndexPage />, index: true },
      // { path: 'user', element: <ClientOverviewUserPage /> },
      // { path: 'client', element: <ClientOverviewClientPage /> },
      // { path: 'contact', element: <ClientOverviewContactPage /> },
      // { path: 'task', element: <ClientOverviewTaskPage /> },
      { path: 'report', element: <ClientOverviewReportPage /> },
      {
        path: 'user',
        children: [
          { element: <ContractorOverviewUserPage />, index: true },
          { path: 'addUser', element: <AddContractorUserPage /> },
          { path: ':id/editUser', element: <EditContractorUserPage /> },
          { path: ':id/userView', element: <ContractorUserView /> },
        ],
      },
      {
        path: 'client',
        children: [
          { element: <ContractorOverviewClientPage />, index: true },
          { path: 'addclient', element: <AddContractorClientPage /> },
          { path: ':id/editClient', element: <EditContractorClientPage /> },
          { path: ':id/clientdetailview/:selectedId', element: <ContractorClientDetailView /> },
          { path: ':id/clientdocdetailsview/:company_type_id', element: <ContractorClientDocDetailsViewPage /> },
        ],
      },
      {
        path: 'assets',
        children: [
          { element: <ContractorOverviewAssetsPage />, index: true },
          { path: 'addAssets', element: <AddContractorAssetsPage /> },
          { path: ':id/editAssets/:building_id', element: <EditContractorAssetsPage /> },
          { path: ':id/assetsView/:selectedbuildingId', element: <ContractorAssetsView /> },
        ],
      },
      {
        path: 'task',
        children: [
          { element: <ContractorOverviewTaskPage />, index: true },
          { path: 'addTask', element: <AddContractorTaskPage /> },
          { path: ':id/editTask/:building_id', element: <EditContractorTaskPage /> },
          { path: ':id/taskView/:selectedbuildingId', element: <ContractorTaskView /> },
        ],
      },
      {
        path: 'contact',
        children: [
          { element: <ContractorOverviewContactPage />, index: true },
          { path: 'addContacts', element: <AddContractorContactsPage /> },
          { path: ':id/editContacts/:company_id', element: <EditContractorContactsPage /> },
          { path: ':id/ContactDetailsView', element: <ContractorContactDetailsView /> },
        ],
      },
      {
        path: 'building',
        children: [
          { element: <ContractorOverviewBuildingPage />, index: true },
          { path: 'addbuilding', element: <AddContractorBuildingPage /> },
          { path: ':id/buildingView', element: <ContractorBuildingView /> },
          { path: ':id/editbuilding', element: <EditContractorBuildingPage /> },
          { path: ':id/buildingDocView', element: <ContractorBuildingDocView /> },
        ],
      },

      {
        path: 'permission',
        children: [
          { element: <ContractorOverviewPermissionPage />, index: true },
          { path: 'addpermission', element: <AddContractorPermissionPage /> },
          { path: ':id/editpermission', element: <EditContractorPermissionPage /> },

        ],
      },

    ],
  },
]